.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-size: cover;
    padding: var(--theme-values-padding-main);

    display: grid;
    grid-template-rows: 1fr auto;
    grid-gap: var(--theme-values-grid_gap-min);
}

.container > * {
    opacity: 1;
}

.container > #title {
    /*text-transform: uppercase;*/
}
