.container {
    max-width: 1000px;
    margin: 0 auto;

    padding: var(--theme-values-padding-main) 0;
}

@media (max-width: 1000px) {
    .container {
        padding: var(--theme-values-padding-main);
    }
}
