@import url('https://v1.fontapi.ir/css/SFProDisplay');

:root {
    --theme-values-padding-min: 10px;
    --theme-values-padding-main: 20px;

    --theme-values-border_radius-main: 15px;
    --theme-values-border_radius-max: 99px;

    --theme-values-grid_gap-min: 8px;
    --theme-values-grid_gap-main: 20px;
    --theme-values-grid_gap-big: 30px;

    --theme-component-grid-image-background: white;
    --theme-component-grid-image-size: 60px;

    --theme-component-box-main_height: 150px;
    --theme-component-box-max_height: 250px;

    --theme-color-main: rgba(255, 255, 255, 0.6);

    --theme-values-mobile-max_width: 800px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    -webkit-user-drag: none;
}

html {
    font-family: 'SF Pro Display', sans-serif;

    background: #181818;
}

h1 {
    font-size: 60px;
}

h3 {
    font-weight: 400;
}

a {
    text-decoration: none;
    color: inherit;
}

mark {
    /*font-weight: 500;*/
    background: none;
    color: inherit;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: none;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--theme-color-main);
    border-radius: 10px;
}
