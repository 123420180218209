.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 24px);
    overflow: auto visible;
    transition: transform .4s;
}

.image:hover {
    transform: scale(1.04);
}

.image {
    background: var(--theme-component-grid-image-background);
    height: var(--theme-component-grid-image-size);
    aspect-ratio: 1 / 1;
    border-radius: var(--theme-values-border_radius-max);
}
