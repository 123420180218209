.container {
    position: relative;

    display: grid;
    grid-gap: var(--theme-values-grid_gap-min);
    align-content: center;

    padding: var(--theme-values-padding-main);
    border-radius: var(--theme-values-border_radius-main);

    overflow: hidden;
    
    transition: all 0.3s cubic-bezier(.14,1,.94,.85);
}

.container:hover {
    transform: scale(1.03);
}

#spaced {
    grid-template-rows: 1fr auto;
}

@media (max-width: 800px) {
    .container {
        grid-column-start: 1 !important;
        grid-column-end: 7 !important;
    }
}

#full {
    min-height: var(--theme-component-box-main_height);
}

.container > img {
    width: 100%;
}
