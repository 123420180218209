.container {
    display: grid;
    gap: var(--theme-values-grid_gap-min);

    max-height: var(--theme-component-box-max_height);
    overflow-y: auto;
}

.grid {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--theme-values-grid_gap-main);
}

.grid > img {
    height: 40px;
}
